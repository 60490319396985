.timeline{
z-index: 1;
width: 1px !important;
}

.chat-image{
  z-index: 2;
}

.bg-lightgray {
  background-color: #f9fafb !important;
}

.time-message:before {
  position: absolute;
  inset-block-start: 0;
  width: 100%;
  height: 2px;
  content: "";
  inset-inline-start: 0;
  background-color: aqua;
  inset-inline-end: 0;
}

/* .tooltip-inner{
  background-color: #e6eaee !important;
  color: black !important;
} */