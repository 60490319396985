#custom-tooltip {
  z-index: 9999;
}

.custom-switch .custom-control-input:checked~.custom-control-label::before {
  background-color: #82c035;
  /* Change this to your desired color */
}

.traffic-container {
  width: "200px";
}

.css-1omp2b7.Mui-selected {
  background-color: #8fbd56 !important;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #8fbd56 !important;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
  background-color: #8fbd56 !important;
}



select.btn-primary option {
  background-color: white;
  color: #333;
}

.closeIcon {
  color: white;
}

.custom-select .MuiOutlinedInput-notchedOutline {
  border-color: grey !important;
  box-shadow: none !important;
  border-style: none !important;

}

.custom-select:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgb(96, 151, 8) !important;
  box-shadow: none !important;

}

.custom-select.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(96, 151, 8) !important;
  box-shadow: none !important;
}

.ql-container.ql-snow {
  height: 300px !important;
  font-size: 15px !important;
  line-height: 1.5 !important;
  color: #000 !important;
  font-family: inherit;
}


.ck.ck-balloon-panel {
  z-index: 10000 !important;
}






.heightSelectOption{
height: 42px !important;
}


/* .ck-balloon-panel_visible{
  display: none !important  ;
} */

/* .ck.ck-powered-by{
  display: none !important;
} */