/*Gradient variables*/
/*Background variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*shadow variables*/
/*Dark Theme Variables*/
/*Transparent variables*/
/*---------- Header-styles ----------*/
/*Header-Color*/
.color-header .main-header-center .form-control {
  color: #fff;
}
.color-header .main-header-center .form-control::-moz-placeholder {
  color: #fff !important;
  opacity: 0.3;
}
.color-header .main-header-center .form-control::placeholder {
  color: #fff !important;
  opacity: 0.3;
}
.color-header .hor-header .header-brand1 {
  margin-top: 4px;
}

.color-header .main-header-center .form-control::-moz-placeholder {
  color: #fff !important;
  opacity: 0.3;
}

.color-header .main-header-center .form-control::placeholder {
  color: #fff !important;
  opacity: 0.3;
}
.color-header .app-header,
.color-header .hor-header {
  background: var(--primary-bg-color);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.color-header .app-sidebar__toggle:hover {
  color: #fff;
}
.color-header .demo-icon.nav-link i {
  color: #e7e7f7 !important;
}
.color-header .animated-arrow {
  background: none;
}
.color-header .animated-arrow span {
  background: #e7e7f7;
}
.color-header .animated-arrow span:before, .color-header .animated-arrow span:after {
  background: #e7e7f7;
}
.color-header.active .animated-arrow span {
  background-color: transparent;
}
.color-header .header .form-inline .form-control {
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
}
.color-header .header .form-inline .btn i {
  color: #fff !important;
  opacity: 0.5;
}
.color-header .header .form-inline .form-control::-moz-placeholder {
  color: #fff !important;
  opacity: 0.3;
}
.color-header .header .form-inline .form-control::placeholder {
  color: #fff !important;
  opacity: 0.3;
}
.color-header .app-sidebar__toggle {
  color: #fff;
}
.color-header .app-sidebar__toggle a {
  color: #fff;
}
.color-header .hor-header .header-brand-img.light-logo {
  display: block;
}
.color-header .logo-horizontal .header-brand-img.light-logo1 {
  display: none;
}

@media (min-width: 992px) {
  .color-header .hor-header.header .header-brand .header-brand-img.desktop-lgo {
    display: none;
  }
  .color-header .hor-header.header .header-brand .header-brand-img.dark-logo {
    display: block;
  }
}
@media (min-width: 991px) {
  .color-header .header .nav-link.icon i,
  .color-header .header-right .nav-link.icon:hover i {
    color: #fff !important;
  }
  .color-header .nav-link.icon {
    color: #fff !important;
  }
  .color-header .nav-link i {
    color: #e7e7f7 !important;
  }
  .color-header .nav-link i:hover {
    color: #fff;
  }
}
@media (max-width: 991px) {
  .color-header .hor-header.header .header-brand-img.mobile-logo {
    display: none !important;
  }
  .color-header .hor-header.header .header-brand-img.darkmobile-logo {
    display: block;
  }
  .color-header .hor-header .header-brand-img.desktop-logo {
    display: none;
  }
  .color-header .hor-header .header-brand-img.light-logo {
    margin: 0 auto;
    margin-top: 6px;
  }
}
@media (max-width: 1024px) and (min-width: 992px) {
  .color-header .hor-header.header .header-brand-img.desktop-lgo, .color-header .hor-header.header .header-brand-img.mobile-logo {
    display: none !important;
  }
  .color-header .hor-header.header .header-brand-img.dark-logo {
    display: block !important;
  }
}
@media (max-width: 991px) {
  .color-header .app-header .header-brand1 .header-brand-img.light-logo1 {
    display: none !important;
  }
  .color-header .app-header .header-brand1 .header-brand-img.desktop-logo {
    display: block !important;
  }
  .color-header .app-header.header .header-brand-img.desktop-logo {
    display: block;
  }
  .color-header .logo-horizontal .header-brand-img.light-logo1 {
    display: none !important;
  }
}
.dark-mode.color-header .app-header,
.dark-mode.color-header .hor-header {
  background: var(--primary-bg-color) !important;
}
.dark-mode.color-header .header .form-inline .form-control {
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  color: #fff;
}

/*Header-dark*/
@media (min-width: 992px) {
  .dark-header .main-header-center .form-control {
    background: #2c2f3e !important;
  }
}
.dark-header .app-header,
.dark-header .hor-header {
  background: #2c2f3e !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.dark-header .hor-header .header-brand1 {
  margin-top: 4px;
}
.dark-header .animated-arrow {
  background: none;
}
.dark-header .animated-arrow span {
  background: var(--primary-bg-color);
}
.dark-header .animated-arrow span:before, .dark-header .animated-arrow span:after {
  background: var(--primary-bg-color);
}
.dark-header.active .animated-arrow span {
  background-color: transparent;
}
.dark-header .header.hor-header .nav-link.icon,
.dark-header .header-right-icons .nav-link.icon:hover {
  color: #e7e7f7 !important;
}
.dark-header .app-header.header .nav-link:hover {
  color: #fff;
}
.dark-header .app-sidebar__toggle:hover {
  color: #fff;
}
.dark-header .header .main-header-center .form-control {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: #fff;
}
.dark-header .header .form-inline .btn i {
  color: #fff;
  opacity: 0.5;
}
.dark-header .header .form-inline .form-control::-moz-placeholder {
  color: #fff;
  opacity: 0.3;
}
.dark-header .header .form-inline .form-control::placeholder {
  color: #fff;
  opacity: 0.3;
}
.dark-header .header.hor-header .header-brand-img.desktop-logo {
  display: block;
}
.dark-header .header.hor-header .header-brand-img.light-logo {
  display: block;
}
.dark-header.horizontal .logo-horizontal .header-brand-img.light-logo1 {
  display: none;
}
.dark-header .app-header .header-right-icons .nav-link.icon {
  color: #d5d7df !important;
}
.dark-header .app-header .header-right-icons .nav-link.icon:hover {
  color: #fff;
}
.dark-header .app-sidebar__toggle {
  color: #d5d7df !important;
}
.dark-header .app-sidebar__toggle:after {
  background: none;
}
.dark-header .nav-link.icon i::after {
  background: none;
}

@media (max-width: 992px) {
  .dark-header .app-header .header-brand1 .header-brand-img.light-logo1 {
    display: none !important;
  }
  .dark-header .app-header .header-brand1 .header-brand-img.desktop-logo {
    display: block;
  }
  .dark-header .hor-header .header-brand-img.desktop-logo {
    display: none;
  }
  .dark-header .hor-header .header-brand-img.light-logo {
    margin: 0 auto;
    margin-top: 6px;
  }
  .dark-header .app-header.header .header-brand-img.desktop-logo {
    display: block;
  }
  .dark-header .logo-horizontal .header-brand-img.light-logo1 {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .dark-header .hor-header.header .header-brand .header-brand-img.desktop-lgo {
    display: none;
  }
  .dark-header .hor-header.header .header-brand .header-brand-img.dark-logo {
    display: block;
  }
  .dark-header .header .nav-link #profile-heading {
    color: rgba(255, 255, 255, 0.8) !important;
  }
}
@media (max-width: 1024px) and (min-width: 992px) {
  .dark-header .hor-header.header .header-brand-img.desktop-lgo, .dark-header .hor-header.header .header-brand-img.mobile-logo {
    display: none !important;
  }
  .dark-header .hor-header.header .header-brand-img.dark-logo {
    display: block !important;
  }
}
.light-mode.dark-header .hor-header .mobile-header.hor-mobile-header {
  background: #30304d !important;
}

/*Header-light*/
.dark-mode.header-light .app-header,
.dark-mode.header-light .hor-header {
  background: #fff !important;
  border-bottom: 1px solid rgb(236, 240, 250) !important;
}
.dark-mode.header-light .app-sidebar {
  box-shadow: none;
}
.dark-mode.header-light .animated-arrow span {
  background: #5c678f;
}
.dark-mode.header-light .animated-arrow span:before, .dark-mode.header-light .animated-arrow span:after {
  background: #5c678f;
}
.dark-mode.header-light.active .animated-arrow span {
  background-color: transparent;
}
.dark-mode.header-light .header .form-inline .form-control {
  border: 1px solid transparent !important;
  background: #f1f5ff;
  color: #000;
}
.dark-mode.header-light .hor-header .header-brand-img.desktop-logo-1 {
  display: none;
}
.dark-mode.header-light .drop-profile a {
  color: #171a29;
}
.dark-mode.header-light .hor-header .header-brand-img.desktop-logo {
  display: block;
}
.dark-mode.header-light .header .nav-link.icon i {
  color: var(--dark-theme);
}
.dark-mode.header-light .header .nav-link.icon i {
  color: var(--primary-bg-color);
}
.dark-mode.header-light .app-sidebar__toggle a {
  color: #555b95;
}

@media (max-width: 575.98px) {
  .dark-mode.header-light .header .form-inline .form-control {
    background: #191d43;
  }
}
@media (max-width: 991px) {
  .dark-mode.header-light .hor-header .header-brand-img.desktop-logo {
    display: none;
  }
  .dark-mode.header-light .app-header .header-brand1 .header-brand-img.desktop-logo {
    display: none;
  }
  .dark-mode.header-light .app-header .header-brand1 .header-brand-img.light-logo1 {
    display: block !important;
  }
  .dark-mode.header-light .mobile-header {
    background-color: #fff;
  }
  .dark-mode.header-light .mobile-header .header-brand-img.desktop-logo {
    display: none !important;
  }
  .dark-mode.header-light .mobile-header .header-brand-img.desktop-logo.mobile-light {
    display: block !important;
  }
}
@media (max-width: 991px) and (min-width: 568px) {
  .app.dark-mode.header-light .app-header .header-brand .header-brand-img.desktop-lgo {
    display: block;
  }
  .app.dark-mode.header-light .app-header .header-brand .header-brand-img.dark-logo, .app.dark-mode.header-light .app-header .header-brand .header-brand-img.mobile-logo, .app.dark-mode.header-light .app-header .header-brand .header-brand-img.darkmobile-logo {
    display: none !important;
  }
}
@media (max-width: 1024px) and (min-width: 992px) {
  .dark-mode.header-light .hor-header.header .header-brand-img.dark-logo, .dark-mode.header-light .hor-header.header .header-brand-img.mobile-logo {
    display: none !important;
  }
  .dark-mode.header-light .hor-header.header .header-brand-img.desktop-lgo {
    display: block !important;
  }
}
/*Header-Gradient-header*/
.gradient-header .pulse {
  background: #fff !important;
}
.gradient-header .hor-header .header-brand1 {
  margin-top: 4px;
}
.gradient-header .app-header,
.gradient-header .hor-header {
  background: linear-gradient(to right, var(--primary-bg-color) 0%, #21bc7c 100%) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.gradient-header .main-header-center .form-control {
  color: #fff;
}
.gradient-header .main-header-center .form-control::-moz-placeholder {
  color: #fff !important;
  opacity: 0.3;
}
.gradient-header .main-header-center .form-control::placeholder {
  color: #fff !important;
  opacity: 0.3;
}
.gradient-header .animated-arrow {
  background: none;
}
.gradient-header .animated-arrow span {
  background: #e7e7f7;
}
.gradient-header .animated-arrow span:before, .gradient-header .animated-arrow span:after {
  background: #e7e7f7;
}
.gradient-header.active .animated-arrow span {
  background-color: transparent;
}
.gradient-header .app-header.header .nav-link:hover {
  color: #fff;
}
.gradient-header .app-sidebar__toggle:hover {
  color: #fff;
}
.gradient-header .demo-icon.nav-link.icon i {
  color: #e7e7f7 !important;
}
.gradient-header .header .main-header-center .form-control {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  background: rgba(0, 0, 0, 0.08) !important;
  color: #fff;
}
.gradient-header .header .main-header-center .form-control:placeholder {
  color: #fff !important;
  opacity: 0.5;
}
.gradient-header .app-sidebar__toggle {
  color: #fff;
}
.gradient-header .app-sidebar__toggle a {
  color: #fff;
}
.gradient-header .app-header .header-right-icons .nav-link.icon {
  color: #fff;
}
.gradient-header .app-header .header-right-icons .nav-link.icon:hover {
  color: #fff !important;
}
.gradient-header .hor-header .header-right-icons .nav-link.icon {
  color: #fff !important;
}

@media (min-width: 991px) {
  .gradient-header .header .nav-link.icon i,
  .gradient-header .header-right .nav-link.icon:hover i {
    color: #fff !important;
  }
  .gradient-header .nav-link.icon i {
    color: #fff !important;
  }
  .gradient-header .nav-link i {
    color: #e7e7f7 !important;
  }
  .gradient-header .nav-link i:hover {
    color: #fff;
  }
  .gradient-header.horizontal .logo-horizontal .header-brand-img.light-logo1 {
    display: none;
  }
  .gradient-header.horizontal .logo-horizontal .header-brand-img.desktop-logo {
    display: block;
  }
}
@media (min-width: 992px) {
  .gradient-header .hor-header.header .header-brand1 .header-brand-img.desktop-logo {
    display: none !important;
  }
  .gradient-header .hor-header.header .header-brand1 .header-brand-img.light-logo {
    display: block;
  }
  .gradient-header .hor-header.header .header-brand2 .header-brand-img.desktop-logo {
    display: none !important;
  }
  .gradient-header .hor-header.header .header-brand2 .header-brand-img.light-logo {
    display: block;
  }
}
@media (max-width: 991px) and (min-width: 575.98px) {
  .gradient-header .app-header .header-brand .header-brand-img.dark-logo {
    display: block;
  }
  .gradient-header .app-header .header-brand .header-brand-img.desktop-lgo, .gradient-header .app-header .header-brand .header-brand-img.mobile-logo, .gradient-header .app-header .header-brand .header-brand-img.darkmobile-logo {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .gradient-header .app-header .header-brand1 .header-brand-img.desktop-logo {
    display: block;
  }
  .gradient-header .app-header .header-brand1 .header-brand-img.light-logo1 {
    display: none !important;
  }
  .gradient-header .navresponsive-toggler span {
    color: #fff !important;
  }
  .gradient-header .hor-header .header-brand-img.desktop-logo {
    display: block !important;
  }
  .gradient-header .hor-header .header-brand-img.light-logo {
    margin: 0 auto;
    margin-top: 6px;
    display: block;
  }
  .gradient-header .app-header.header .header-brand-img.desktop-logo {
    display: block;
  }
  .gradient-header .logo-horizontal .header-brand-img.light-logo1 {
    display: none !important;
  }
}
@media (max-width: 1024px) and (min-width: 992px) {
  .gradient-header .hor-header.header .header-brand-img.desktop-lgo, .gradient-header .hor-header.header .header-brand-img.mobile-logo {
    display: none !important;
  }
  .gradient-header .hor-header.header .header-brand-img.dark-logo {
    display: block !important;
  }
}
.dark-mode.gradient-header .app-header,
.dark-mode.gradient-header .hor-header {
  background: linear-gradient(to right, var(--primary-bg-color) 0%, #21bc7c 100%) !important;
}
.dark-mode.gradient-header .header .form-inline .form-control {
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  color: #fff;
}

/*---------- Leftmenu-styles ----------*/
/*Light-menu*/
.light-menu .app-sidebar {
  background: #fff;
}
.light-menu .app-sidebar .app-sidebar__user {
  border-bottom: 1px solid #eaedf1;
}
.light-menu .app-sidebar .side-menu .sidemenu_icon {
  color: #fff !important;
}
.light-menu .app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
  border: 3px solid #ebeff8;
}
.light-menu .app-sidebar .app-sidebar__user .user-pic::before {
  border: 2px solid #fff;
}
.light-menu .app-sidebar .app-sidebar__user .user-info h5 {
  color: #263871;
}
.light-menu .app-sidebar .Annoucement_card {
  background: #ebeff8;
}
.light-menu .app-sidebar .Annoucement_card .title,
.light-menu .app-sidebar .Annoucement_card .text-white {
  color: #263871 !important;
}
.light-menu .slide a.active .sidemenu_icon,
.light-menu .side-menu__item:hover .sidemenu_icon {
  color: #fff;
}
.light-menu .slide-menu li.active > a, .light-menu .slide-menu li:hover > a {
  color: var(--primary-bg-color) !important;
}
.light-menu .side-item.side-item-category {
  opacity: 0.7 !important;
}
.light-menu.dark-mode.sidebar-mini.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
  display: block !important;
}
.light-menu.dark-mode.sidebar-mini.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
  display: none !important;
}
.light-menu.dark-mode.sidebar-mini.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
  display: block !important;
}
.light-menu.dark-mode.sidebar-mini.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
  display: none !important;
}
.light-menu.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled .side-menu-label1 {
  border-bottom-color: rgb(225, 229, 233);
}

.dark-mode.light-menu .app-sidebar {
  background: #fff !important;
}
.dark-mode.light-menu .app-sidebar .app-sidebar__user {
  border-bottom: 1px solid #eaedf1;
}
.dark-mode.light-menu .app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
  border: 3px solid #ebeff8;
}
.dark-mode.light-menu .app-sidebar .app-sidebar__user .user-pic::before {
  border: 2px solid #fff;
}
.dark-mode.light-menu .slide-menu li.active > a, .dark-mode.light-menu .slide-menu li:hover > a {
  color: var(--primary-bg-color) !important;
}

@media (min-width: 992px) {
  .light-menu .app-sidebar__logo {
    border-bottom: 1px solid #eaedf1;
    border-right: 1px solid #eaedf1;
  }
  .light-menu .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo {
    display: block;
    margin: 0 auto;
  }
  .light-menu .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo {
    display: none;
  }
  .light-menu.sidenav-toggled .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo {
    display: none;
  }
  .light-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo {
    display: block;
    margin: 0 auto;
  }
  .light-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__logo .header-brand-img.dark-logo {
    display: none;
  }
  .light-menu.light-menu.dark-mode.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
    background: #fff !important;
  }
  .light-menu.light-menu.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
    background: #fff !important;
  }
  .dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .side-header {
    border-right: 1px solid #eaedf1 !important;
  }
  .dark-mode.light-menu .side-header {
    border-bottom: 1px solid #eaedf1 !important;
    border-right: 1px solid #eaedf1 !important;
    top: 0px;
  }
  .dark-mode.light-menu .side-menu h3 {
    color: #76839a;
  }
  .dark-mode.light-menu .side-menu__item {
    color: #495584 !important;
  }
  .dark-mode.light-menu .side-menu__item.active:hover,
  .dark-mode.light-menu .side-menu__item.active:focus {
    color: #fff;
  }
  .dark-mode.light-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: block !important;
    margin: 0 auto;
  }
  .dark-mode.light-menu .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
  }
  .dark-mode.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
  }
  .dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: block !important;
    margin: 0 auto;
  }
  .dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-imgdesktop-logo {
    display: none !important;
  }
}
.app.sidebar-mini.dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: none !important;
}

.app.sidebar-mini.dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: block !important;
}

.app.sidebar-mini.dark-mode.hover-submenu1.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.dark-mode.light-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

/*Color-menu*/
@media (min-width: 992px) {
  .color-menu.app.sidebar-mini.sidenav-toggled .side-menu__icon, .color-menu.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__icon {
    color: #fff !important;
    fill: #fff !important;
  }
}
.color-menu .slide-item {
  color: rgba(255, 255, 255, 0.8);
}
.color-menu .slide-item:before {
  color: rgba(255, 255, 255, 0.8) !important;
}
.color-menu .slide-item:hover {
  color: #fff !important;
}
.color-menu .slide-item:hover:before {
  color: #fff !important;
}
.color-menu .sub-side-menu__item:hover {
  color: #fff;
}
.color-menu .sub-slide-item:hover {
  color: #fff;
}
.color-menu .sub-side-menu__item2:hover {
  color: #fff;
}
.color-menu .sub-slide-item2:hover {
  color: #fff;
}
.color-menu .sub-side-menu__item {
  color: rgba(255, 255, 255, 0.8);
}
.color-menu .sub-slide-item {
  color: rgba(255, 255, 255, 0.8);
}
.color-menu .sub-side-menu__item2 {
  color: rgba(255, 255, 255, 0.8);
}
.color-menu .sub-slide-item2 {
  color: rgba(255, 255, 255, 0.8);
}
.color-menu .side-menu__item:hover .side-menu__icon,
.color-menu .side-menu__item:hover .side-menu__label,
.color-menu .side-menu__item:focus .side-menu__icon,
.color-menu .side-menu__item:focus .side-menu__label {
  color: #fff !important;
  fill: #fff !important;
}
.color-menu .side-menu__item:hover {
  color: #fff !important;
}
.color-menu .side-menu__item {
  color: rgba(255, 255, 255, 0.8) !important;
}
.color-menu .side-menu .side-menu__icon {
  color: rgba(255, 255, 255, 0.8) !important;
  fill: rgba(255, 255, 255, 0.8) !important;
}
.color-menu .side-menu__item.active .side-menu__icon {
  color: #fff !important;
  fill: #fff !important;
}
.color-menu .side-menu__item.active .side-menu__label {
  color: #fff !important;
}
.color-menu .app-sidebar {
  border-right: 1px solid var(--dark-border);
  background-color: var(--primary-bg-color) !important;
}
.color-menu .side-menu h3 {
  color: rgba(255, 255, 255, 0.7);
}
.color-menu .app-sidebar .side-header {
  background: var(--primary-bg-color);
  border-right: 1px solid var(--dark-border);
}
.color-menu .app-sidebar .side-header .side-item.side-item-category {
  color: #fff;
  opacity: 0.3;
}
.color-menu .app-sidebar .side-header .side-menu .side-menu__icon,
.color-menu .app-sidebar .side-header .side-menu .side-menu__item {
  color: rgba(255, 255, 255, 0.9);
}
.color-menu .app-sidebar .side-header .slide a.active .sidemenu_icon,
.color-menu .app-sidebar .side-header .side-menu__item:hover .sidemenu_icon {
  color: #fff !important;
  opacity: inherit;
}
.color-menu .app-sidebar .side-header .side-menu .slide a.active,
.color-menu .app-sidebar .side-header .side-menu .slide a:hover {
  color: #fff !important;
  opacity: 1 !important;
}
.color-menu .app-sidebar .side-header .slide .side-menu__item.active,
.color-menu .app-sidebar .side-header .slide .side-menu__item:hover {
  background: rgba(0, 0, 0, 0.1);
}
.color-menu .app-sidebar .side-header .side-menu__item.active:hover,
.color-menu .app-sidebar .side-header .side-menu__item.active:focus {
  background: linear-gradient(to right, var(--primary-bg-color) 0%, #21bc7c 100%) !important;
}
.color-menu .app-sidebar .side-header .side-menu__item:hover .side-menu__label,
.color-menu .app-sidebar .side-header .side-menu__item:hover .side-menu__icon,
.color-menu .app-sidebar .side-header .side-menu__item:hover .angle {
  color: #fff !important;
  opacity: inherit;
}
.color-menu .app-sidebar .side-header .app-sidebar__user {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.color-menu .app-sidebar .side-header .app-sidebar__user .user-info h5 {
  color: #fff;
}
.color-menu .app-sidebar .side-header .app-sidebar__user .app-sidebar__user-name.text-muted {
  color: #fff !important;
  opacity: 0.7;
}
.color-menu .app-sidebar .side-header .app-sidebar__user .user-pic .avatar-xxl {
  border: 3px solid #fff;
}
.color-menu.horizontal .horizontal-main .slide .slide-menu, .color-menu.horizontal .horizontal-main .slide .sub-slide-menu, .color-menu.horizontal .horizontal-main .slide .sub-slide-menu2 {
  background-color: var(--primary-bg-color);
  border-color: var(--dark-border);
}
.color-menu.horizontal .side-menu .slide .side-menu__item.active {
  color: #fff !important;
}
.color-menu .slide-item.active,
.color-menu .slide-item:hover,
.color-menu .slide-item:focus {
  color: #fff !important;
}
.color-menu .is-expanded .sub-slide-item.active,
.color-menu .sub-side-menu__item.active.is-expanded {
  color: #fff;
}

@media (min-width: 992px) {
  .color-menu .side-header {
    border-bottom: 1px solid var(--dark-border);
    border-right: 1px solid var(--dark-border);
  }
  .color-menu .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: block;
    margin: 0 auto;
  }
  .color-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none;
  }
  .color-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none;
  }
  .color-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block !important;
  }
  .color-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo, .color-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo1, .color-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.mobile-light {
    display: none;
  }
  .color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: block !important;
    margin: 0 auto;
  }
  .color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: none !important;
  }
  .color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo, .color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
  }
  .color-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
  }
  .color-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block !important;
  }
  .color-menu.hover-submenu.dark-mode.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
  }
  .color-menu.hover-submenu.dark-mode.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block !important;
  }
  .color-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
  }
  .color-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block !important;
  }
  .color-menu.hover-submenu1.dark-mode.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
  }
  .color-menu.hover-submenu1.dark-mode.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block !important;
  }
  .color-menu.sidenav-toggled .slide-menu {
    background: var(--primary-bg-color) !important;
    color: #fff;
  }
  .color-menu.sidenav-toggled .sub-side-menu__item, .color-menu.sidenav-toggled .sub-side-menu__item2 {
    color: rgba(255, 255, 255, 0.9);
  }
  .color-menu.sidenav-toggled .sub-slide-item, .color-menu.sidenav-toggled .sub-slide-item2 {
    color: rgba(255, 255, 255, 0.9);
  }
  .color-menu.sidenav-toggled .slide-item:hover, .color-menu.sidenav-toggled .slide-item:focus {
    color: #fff !important;
  }
  .color-menu .sub-side-menu__item {
    color: #fff;
  }
}
.dark-mode.color-menu .side-menu .side-menu__icon,
.dark-mode.color-menu .side-menu__item,
.dark-mode.color-menu .slide-item {
  color: #fff !important;
  fill: #fff !important;
}
.dark-mode.color-menu .slide-menu li .slide-item:before {
  color: #fff !important;
}
.dark-mode.color-menu .app-sidebar {
  background: var(--primary-bg-color);
}
.dark-mode.color-menu .app-sidebar .side-menu .slide a {
  color: #fff;
}
.dark-mode.color-menu .app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
  border: 3px solid #fff;
}
.dark-mode.color-menu .app-sidebar .app-sidebar__user .user-pic::before {
  border: 2px solid #fff;
}
.dark-mode.color-menu .side-item.side-item-category {
  color: #fff;
  opacity: 0.5;
}
.dark-mode.color-menu .app-sidebar ul li a {
  color: #fff;
}
.dark-mode.color-menu .slide-menu li.active > a, .dark-mode.color-menu .slide-menu li:hover > a {
  color: #fff !important;
  opacity: inherit;
}
.dark-mode.color-menu .app-sidebar .slide-menu .slide-item:hover,
.dark-mode.color-menu .app-sidebar .side-menu__item.active .side-menu__icon {
  color: rgba(255, 255, 255, 0.8) !important;
  fill: rgba(255, 255, 255, 0.8) !important;
  opacity: inherit;
}
.dark-mode.color-menu .slide.is-expanded .side-menu__item {
  color: #fff !important;
  opacity: inherit;
}

@media (min-width: 992px) {
  .dark-mode.color-menu .app-sidebar__logo {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }
}
/*dark-menu*/
.dark-menu .app-sidebar {
  background: #2c2f3e !important;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.dark-menu .app-sidebar .side-item.side-item-category {
  color: #fff;
  opacity: 0.3;
}
.dark-menu .app-sidebar .side-header {
  background: #2c2f3e !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.dark-menu .app-sidebar .side-menu h3 {
  color: rgba(255, 255, 255, 0.3);
}
.dark-menu .app-sidebar .side-menu .side-menu__icon {
  color: #fff !important;
  fill: #fff !important;
}
.dark-menu .app-sidebar .side-menu a {
  color: rgba(255, 255, 255, 0.8) !important;
}
.dark-menu .app-sidebar .side-menu__item {
  color: var(--primary-bg-color) !important;
}
.dark-menu .app-sidebar .slide-item {
  color: rgba(255, 255, 255, 0.8) !important;
}
.dark-menu .app-sidebar .slide a.active .side-menu__icon,
.dark-menu .app-sidebar .side-menu__item:hover .side-menu__icon {
  color: var(--primary-bg-color) !important;
  fill: var(--primary-bg-color) !important;
  opacity: inherit;
}
.dark-menu .app-sidebar .side-menu .slide a.active,
.dark-menu .app-sidebar .side-menu .slide a:hover {
  color: var(--primary-bg-color) !important;
  opacity: 1 !important;
}
.dark-menu .app-sidebar .side-menu__item:hover .side-menu__label,
.dark-menu .app-sidebar .side-menu__item:hover .side-menu__icon,
.dark-menu .app-sidebar .side-menu__item:hover .angle {
  color: var(--primary-bg-color) !important;
  fill: var(--primary-bg-color) !important;
  opacity: inherit;
}
.dark-menu .app-sidebar .side-menu__item:hover .side-menu__icon,
.dark-menu .app-sidebar .side-menu__item:hover .side-menu__label,
.dark-menu .app-sidebar .side-menu__item:focus .side-menu__icon,
.dark-menu .app-sidebar .side-menu__item:focus .side-menu__label {
  color: var(--primary-bg-color) !important;
  fill: var(--primary-bg-color) !important;
}
.dark-menu .app-sidebar .app-sidebar__user {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.dark-menu .app-sidebar .app-sidebar__user .user-info h5 {
  color: #fff;
}
.dark-menu .app-sidebar .app-sidebar__user .app-sidebar__user-name.text-muted {
  color: #fff !important;
  opacity: 0.5;
}
.dark-menu.sidebar-mini.sidenav-toggled .slide-menu {
  background: var(--dark-theme);
}
.dark-menu .slide-menu li .slide-item:before {
  color: #fff !important;
}
.dark-menu .side-menu-label1 {
  border-bottom-color: var(--dark-border) !important;
}
.dark-menu.horizontal .horizontal-main .slide .slide-menu, .dark-menu.horizontal .horizontal-main .slide .sub-slide-menu, .dark-menu.horizontal .horizontal-main .slide .sub-slide-menu2 {
  background-color: #2c2f3e;
  border-color: var(--dark-border);
}

@media (min-width: 992px) {
  .dark-menu .side-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }
  .dark-menu .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: block;
    margin: 0 auto;
  }
  .dark-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none;
  }
  .dark-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none;
  }
  .dark-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: block;
  }
  .dark-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: block;
    margin: 0 auto;
  }
  .dark-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
  }
  .dark-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none;
  }
  .dark-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none;
  }
  .dark-menu.dark-mode.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: block !important;
  }
  .dark-menu.dark-mode.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: none;
  }
  .dark-menu.dark-mode.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
  }
  .dark-menu.dark-mode.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
    display: none !important;
  }
  .dark-menu.dark-mode.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block !important;
  }
  .dark-menu.dark-mode.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
  }
  .dark-menu.dark-mode.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
    display: none !important;
  }
  .dark-menu.dark-mode.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block !important;
  }
}
/*Graident-Color*/
.gradient-menu .slide-menu li .slide-item:before {
  color: #fff !important;
}
@media (min-width: 992px) {
  .gradient-menu.rtl .app-sidebar {
    border-right: inherit;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
  .gradient-menu.rtl .app-sidebar .side-header {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: inherit;
  }
}
.gradient-menu .app-sidebar {
  background: linear-gradient(to bottom, var(--primary-bg-color) 0%, #21bc7c 100%) !important;
}
.gradient-menu .app-sidebar .side-menu__item.active .side-menu__label {
  color: #fff !important;
}
.gradient-menu .app-sidebar .side-item.side-item-category {
  color: #fff;
  opacity: 0.3;
}
.gradient-menu .app-sidebar .side-header {
  background: linear-gradient(to bottom right, #80bc5b 0%, var(--primary-bg-color) 100%);
  border-bottom: 1px solid var(--dark-border);
  border-right: 1px solid var(--dark-border);
}
.gradient-menu .app-sidebar .side-menu__item.active::after {
  background: #fff;
}
.gradient-menu .app-sidebar .side-menu__item:hover::after {
  background: #fff !important;
}
.gradient-menu .app-sidebar .side-menu .slide a {
  color: rgba(255, 255, 255, 0.9);
}
.gradient-menu .app-sidebar .side-menu h3 {
  color: rgba(255, 255, 255, 0.6);
}
.gradient-menu .app-sidebar .side-menu .side-menu__icon {
  color: rgba(255, 255, 255, 0.9) !important;
  fill: rgba(255, 255, 255, 0.9) !important;
}
.gradient-menu .app-sidebar .slide-menu li .slide-item:before {
  color: #c7c9eb;
}
.gradient-menu .app-sidebar .side-menu__item:hover .side-menu__icon,
.gradient-menu .app-sidebar .side-menu__item:hover .side-menu__label,
.gradient-menu .app-sidebar .side-menu__item:focus .side-menu__icon,
.gradient-menu .app-sidebar .side-menu__item:focus .side-menu__label {
  color: #fff !important;
  fill: #fff !important;
}
.gradient-menu .app-sidebar .side-menu .slide a {
  color: rgba(255, 255, 255, 0.9);
}
.gradient-menu .app-sidebar .side-menu__item {
  color: rgba(255, 255, 255, 0.9);
}
.gradient-menu .app-sidebar .slide-item {
  color: rgba(255, 255, 255, 0.9);
}
.gradient-menu .app-sidebar .slide a.active .side-menu__icon,
.gradient-menu .app-sidebar .side-menu__item:hover .side-menu__icon {
  color: #fff !important;
  fill: #fff !important;
  opacity: inherit;
}
.gradient-menu .app-sidebar .side-menu .slide a.active,
.gradient-menu .app-sidebar .side-menu .slide a:hover {
  color: #fff !important;
  opacity: 1 !important;
}
.gradient-menu .app-sidebar .side-menu__item:hover .side-menu__label,
.gradient-menu .app-sidebar .side-menu__item:hover .side-menu__icon,
.gradient-menu .app-sidebar .side-menu__item:hover .angle {
  color: #fff !important;
  fill: #fff !important;
  opacity: inherit;
}
.gradient-menu .app-sidebar .app-sidebar__user {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.gradient-menu .app-sidebar .app-sidebar__user .user-info h5 {
  color: #fff;
}
.gradient-menu .app-sidebar .app-sidebar__user .app-sidebar__user-name.text-muted {
  color: #fff !important;
}
.gradient-menu .app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
  border: 3px solid #fff;
}
.gradient-menu.hover-submenu1 .side-menu-label1 {
  border-bottom-color: var(--dark-border) !important;
}
.gradient-menu.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
  background-color: var(--primary-bg-color) !important;
}
.gradient-menu.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
  background-color: var(--primary-bg-color) !important;
}
@media (min-width: 992px) {
  .gradient-menu.horizontal .horizontal-main .slide .slide-menu, .gradient-menu.horizontal .horizontal-main .slide .sub-slide-menu, .gradient-menu.horizontal .horizontal-main .slide .sub-slide-menu2 {
    background: linear-gradient(to bottom, var(--primary-bg-color) 0%, #21bc7c 100%);
    border-color: var(--dark-border);
  }
}

@media (min-width: 992px) {
  .gradient-menu .app-sidebar {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid var(--dark-border);
  }
  .gradient-menu .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: block;
    margin: 0 auto;
  }
  .gradient-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none;
  }
  .gradient-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none;
  }
  .gradient-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block !important;
  }
  .gradient-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo, .gradient-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo1, .gradient-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.mobile-light {
    display: none;
  }
  .gradient-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: block;
    margin: 0 auto;
  }
  .gradient-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: none !important;
  }
  .gradient-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
  }
}
.dark-mode.gradient-menu .app-sidebar {
  background: linear-gradient(to bottom, var(--primary-bg-color) 0%, #21bc7c 100%) !important;
}
.dark-mode.gradient-menu .app-sidebar .side-menu .slide a {
  color: #fff !important;
}
.dark-mode.gradient-menu .app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
  border: 3px solid #fff;
}
.dark-mode.gradient-menu .app-sidebar .app-sidebar__user .user-pic::before {
  border: 2px solid #fff;
}
.dark-mode.gradient-menu .side-item.side-item-category {
  color: #fff;
  opacity: 0.5;
}
.dark-mode.gradient-menu .app-sidebar ul li a {
  color: #fff;
}
.dark-mode.gradient-menu .slide-menu li.active > a, .dark-mode.gradient-menu .slide-menu li:hover > a {
  color: #fff !important;
  opacity: inherit;
}
.dark-mode.gradient-menu .app-sidebar .slide-menu .slide-item:hover,
.dark-mode.gradient-menu .app-sidebar .side-menu__item.active .side-menu__icon {
  color: #fff !important;
  fill: #fff !important;
  opacity: inherit;
}
.dark-mode.gradient-menu .slide.is-expanded .side-menu__item {
  color: #fff !important;
  opacity: inherit;
}

@media (min-width: 992px) {
  .dark-mode.gradient-menu .app-sidebar__logo {
    border-bottom: 1px solid var(--dark-border);
    border-right: 1px solid var(--dark-border);
  }
}
/*---------- Horizontalmenu-styles ----------*/
.dark-mode.color-header.gradient-menu .app-sidebar {
  box-shadow: none;
}
.dark-mode.gradient-header.gradient-header .app-sidebar {
  box-shadow: none;
}
.dark-mode.color-menu.color-header .app-sidebar {
  box-shadow: none;
}
.dark-mode.light-menu.color-header .app-sidebar {
  box-shadow: none;
}

.gradient-header.color-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: none;
}

.dark-header.color-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: none;
}

.color-header.color-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: none;
}

.gradient-header.gradient-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: none;
}

.dark-header.gradient-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: none;
}

.color-header.gradient-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: none;
}

.gradient-header.dark-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: none;
}

.dark-header.dark-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: none;
}

.color-header.dark-menu .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: none;
}

@media (max-width: 991px) {
  .dark-mode .mobile-header {
    background-color: #30304d;
    border: 1px solid transparent;
    box-shadow: none;
  }
}
.color-header .header-brand-img.desktop-logo {
  display: none;
}

.color-header.horizontal .header-brand-img.desktop-logo {
  display: block;
}

.color-header .mobile-header.hor-mobile-header {
  background: var(--primary-bg-color);
}

@media (max-width: 991px) {
  .color-header .mobile-header .header-brand-img.desktop-logo {
    display: block;
  }
  .color-header .mobile-header .header-brand-img.mobile-light {
    display: none !important;
  }
  .dark-header .app-header .header-right-icons .responsive-navbar .nav-link.icon {
    color: #495057 !important;
  }
  .dark-header .navresponsive-toggler span {
    color: #fff;
  }
}
.color-header .main-header-center .form-control {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.color-header .app-sidebar .side-header .header-brand-img.light-logo {
  display: none;
}

.color-header.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
  display: block;
}

.color-header.gradient-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
  display: none;
}

.gradient-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: block !important;
}
.gradient-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
  display: none;
}
.gradient-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: block;
}

.gradient-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: none !important;
}
.gradient-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
  display: none !important;
}
.gradient-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
  display: block !important;
}

.gradient-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: none !important;
}

.gradient-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: none !important;
}
.gradient-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
  display: none !important;
}
.gradient-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
  display: block !important;
}

.header-light .hor-header .header-brand-img.light-logo {
  display: none;
}

.header-light .main-header-center .form-control {
  color: #212529 !important;
  background-color: #fff;
  border: 1px solid #ecf0fa !important;
}

.header-light .header .dropdown .nav-link.icon:hover {
  background: none !important;
}

.header-light.light-menu .side-header {
  border-bottom: 1px solid #eaedf1;
  background: #fff;
}

.header-light.light-menu .app-sidebar {
  background: #fff;
}

.header-light.light-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: block !important;
}
.header-light.light-menu .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: none !important;
}

.header-light.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.header-light.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: block !important;
}

.color-header.light-menu .side-header {
  border-bottom: 1px solid #eaedf1;
  background: #fff;
}

.color-header.light-menu .app-sidebar {
  background: #fff;
}

.color-header.light-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: block !important;
}
.color-header.light-menu .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: none !important;
}

.color-header.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.color-header.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: block !important;
}

.color-header .navresponsive-toggler span {
  color: #fff !important;
}

.gradient-header.light-menu .side-header {
  border-bottom: 1px solid #eaedf1;
  background: #fff;
}

.gradient-header.light-menu .app-sidebar {
  background: #fff;
}

.gradient-header.light-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: block !important;
}
.gradient-header.light-menu .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: none !important;
}

.gradient-header.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.gradient-header.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: block !important;
}

.dark-mode .apexcharts-radialbar-track.apexcharts-track path {
  stroke: #21203a;
}

.dark-mode .apex-charts text {
  fill: #fff;
}

.light-mode.color-header.color-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.light-mode.color-header.color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.light-mode.color-header.color-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
  display: none;
}

.light-mode.color-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.color-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.dark-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.dark-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.gradient-menu .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.app.sidebar-mini.gradient-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.gradient-header .mobile-header.hor-mobile-header {
  background: linear-gradient(to right, var(--primary-bg-color) 0%, #21bc7c 100%) !important;
}

@media (max-width: 991px) {
  .dark-header .mobile-header .header-brand-img.desktop-logo {
    display: block;
  }
  .dark-header .mobile-header .header-brand-img.mobile-light {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .gradient-header .mobile-header .header-brand-img.desktop-logo {
    display: block;
  }
  .gradient-header .mobile-header .header-brand-img.mobile-light {
    display: none !important;
  }
}
.dark-mode.header-light .main-header-center .form-control {
  border: 1px solid #ecf0fa !important;
}

.dark-mode.light-menu .side-menu__item {
  color: #495057;
}

.dark-mode.horizontal.light-menu .side-menu > li > a {
  color: #495057;
}

.dark-mode.horizontal.light-menu .side-menu .sub-side-menu__label,
.dark-mode.horizontal.light-menu .sub-slide-label,
.dark-mode.horizontal.light-menu .sub-side-menu__label2 {
  color: #495057 !important;
}

.dark-mode.horizontal.light-menu .horizontal-main .slide .slide-menu,
.dark-mode.horizontal.light-menu .horizontal-main .slide .sub-slide-menu,
.dark-mode.horizontal.light-menu .horizontal-main .slide .sub-slide-menu2 {
  background-color: #fff;
}

.dark-mode.light-menu .side-menu__icon {
  color: #495584 !important;
  fill: #495584 !important;
}

.dark-mode.light-menu.sidenav-toggled .side-menu__icon {
  color: #495057 !important;
  fill: #495057 !important;
}

.dark-mode.light-menu .slide a {
  color: #495057 !important;
}

.slide-menu li .slide-item:before {
  color: #495057 !important;
}

.dark-mode.light-menu.hover-submenu .slide-item {
  color: #495057 !important;
}

.dark-mode.light-menu.hover-submenu1 .slide-item {
  color: #495057 !important;
}

.dark-mode.light-menu .side-menu .sub-category,
.dark-mode.light-menu .sub-side-menu__item,
.dark-mode.light-menu .sub-slide-item,
.dark-mode.light-menu .sub-side-menu__item2,
.dark-mode.light-menu .sub-slide-item2 {
  color: #495057;
}

.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
  background: var(--dark-body);
}
.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled .side-menu-label1 {
  border-bottom-color: var(--dark-border);
}
.dark-mode.hover-submenu1.sidebar-mini.sidenav-toggled .side-menu-label1 a {
  color: rgba(255, 255, 255, 0.8);
}

.dark-mode.header-light.sidebar-mini .app-header .light-logo1 {
  display: block !important;
}
.dark-mode.header-light.sidebar-mini .app-header.header .header-brand-img.desktop-logo {
  display: none !important;
}

/*--Boxed--*/
@media (min-width: 1400px) {
  .horizontal.layout-boxed.app.dark-mode.light-menu .slide-menu li .slide-item:before {
    color: #495057 !important;
  }
  .horizontal.layout-boxed.app.dark-mode.light-menu .slide-menu li .slide-item:hover:before {
    color: var(--primary-bg-color) !important;
  }
  .horizontal.layout-boxed .stickyClass .app-sidebar {
    max-width: 1400px;
  }
  .horizontal.layout-boxed .app-sidebar.horizontal-main.fixed-header.visible-title {
    max-width: 1400px;
  }
  .layout-boxed.horizontal .hor-header .container,
  .layout-boxed.horizontal .horizontal-main .container,
  .layout-boxed.horizontal .main-content.hor-content .container {
    max-width: 95% !important;
  }
  body.layout-boxed {
    background: #cfcfcf;
  }
  body.layout-boxed.dark-mode {
    background: var(--dark-body);
  }
  .layout-boxed .page {
    width: 1400px;
    margin: 0 auto;
    background: #f2f3f9;
    position: relative;
    display: flex;
    flex-direction: column;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    box-shadow: rgba(104, 113, 123, 0.3) 0px 5px 0px 0px, rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
  }
  .layout-boxed .app-header.header {
    width: 1400px !important;
    margin: 0 auto;
  }
  .layout-boxed .app-sidebar {
    left: auto !important;
  }
  .layout-boxed .side-header {
    left: auto !important;
    right: initial !important;
  }
  .layout-boxed h3.number-font {
    font-size: 1.8rem;
  }
  .layout-boxed .sticky-pin .horizontal-main.hor-menu {
    width: 1400px !important;
  }
  .layout-boxed .horizontalMenu > .horizontalMenu-list > li > a {
    padding: 16px 17px;
  }
  .layout-boxed .hor-content .container,
  .layout-boxed .hor-header .container,
  .layout-boxed .horizontal-main.hor-menu .container {
    max-width: 95%;
  }
  .layout-boxed .main-sidemenu .slide-right {
    right: 6px;
  }
  .layout-boxed .main-sidemenu .slide-left {
    left: 8px;
  }
  .layout-boxed .browser-stats img {
    padding: 5px;
    width: 30px;
    height: 30px;
  }
  .layout-boxed .media.media-xs.overflow-visible {
    display: inline-block;
  }
  .dark-mode.layout-boxed .page {
    background: var(--dark-body);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
  }
}
/*--- Scrollabel-header ----*/
.scrollable-layout .app-header,
.scrollable-layout .mobile-header {
  position: absolute !important;
}
.scrollable-layout .app-sidebar {
  position: absolute;
}
.scrollable-layout .side-header {
  position: absolute;
}
.scrollable-layout .page {
  position: relative;
}
.scrollable-layout .sticky.sticky-pin {
  position: absolute;
}
.scrollable-layout .navbar {
  position: inherit;
}

@media (max-width: 767.98px) {
  .scrollable-layout .responsive-navbar.navbar .navbar-collapse {
    position: absolute;
  }
}
@media (max-width: 991px) {
  .scrollable-layout .app-header,
  .scrollable-layout .hor-header {
    position: absolute;
  }
}
/*--- Only Demo Purpose scss Icon-Text----*/
@media (min-width: 992px) {
  .icontext-menu.sidebar-mini.sidenav-toggled .app-sidebar {
    width: 120px;
  }
  .icontext-menu.app.sidebar-mini.sidenav-toggled .side-header {
    width: 120px !important;
  }
  .icontext-menu.sidebar-mini.sidenav-toggled .app-content {
    margin-left: 120px;
  }
  .icontext-menu.app.sidebar-mini.sidenav-toggled .side-menu__item {
    padding: 12px 5px;
    text-align: center;
    display: block;
  }
  .icontext-menu.sidebar-mini.sidenav-toggled .app-header.header {
    padding-left: 130px;
  }
  .icontext-menu.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon:after {
    top: 6px;
  }
  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
    width: 270px;
  }
  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header {
    width: 270px !important;
  }
  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__item {
    display: flex;
    padding: 10px 20px;
    text-align: inherit;
  }
  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__label {
    display: block !important;
    margin-top: 3px;
    font-size: 15px;
  }
  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu .side-menu__icon:after {
    top: 12px;
  }
}
@media (min-width: 991px) {
  .centerlogo-header .header-brand2 .header-brand-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 18px;
  }
  .centerlogo-header .header-brand1 {
    display: none;
  }
}
.hor-header.centerlogo-header {
  display: none;
}

.centerlogo-horizontal .hor-header.centerlogo-header {
  display: block !important;
}
.centerlogo-horizontal .header.hor-header {
  display: none;
}

.layout-boxed .login-img .page {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.5) 0px 5px 25px 0px;
}

@media (min-width: 1400px) {
  .rtl.layout-boxed .app-sidebar {
    right: auto !important;
  }
}

@media (max-width: 767.98px) {
  .gradient-header .responsive-navbar .navbar-collapse {
    background: linear-gradient(to right, var(--primary-bg-color) 0%, #21bc7c 100%) !important;
  }
  .dark-mode.gradient-header .responsive-navbar .navbar-collapse {
    background: linear-gradient(to right, var(--primary-bg-color) 0%, #21bc7c 100%) !important;
  }
  .gradient-header .nav-link.icon svg {
    fill: #fff !important;
  }
  .gradient-header .navbar-toggler-icon.fe.fe-more-vertical {
    color: #fff !important;
  }
  .gradient-header .header .nav-link.icon:hover svg,
  .header .nav-link.icon:focus svg {
    color: #fff !important;
    fill: #fff !important;
  }
}
@media (max-width: 991px) {
  .dark-mode.header-light .app-sidebar__toggle {
    color: var(--primary-bg-color);
  }
  .dark-mode.header-light .navresponsive-toggler span {
    color: var(--primary-bg-color);
  }
  .dark-mode.color-header .responsive-navbar .navbar-collapse {
    background-color: var(--primary-bg-color) !important;
  }
  .dark-header.horizontal .responsive-navbar .navbar-collapse .nav-link {
    color: #495057 !important;
  }
}
.bg-img2 .side-header {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.dark-mode.light-menu .side-menu .sub-side-menu__label,
.dark-mode.light-menu .sub-slide-label,
.dark-mode.light-menu .sub-side-menu__label2 {
  color: #495057 !important;
}

.dark-mode.color-menu.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
  background: var(--primary-bg-color) !important;
}

.dark-mode.gradient-menu.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
  background: var(--primary-bg-color) !important;
}

.dark-mode.light-menu.hover-submenu.sidebar-mini.sidenav-toggled .slide-menu {
  background: #fff !important;
}

.dark-mode.color-menu.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
  background: var(--primary-bg-color) !important;
}

.dark-mode.gradient-menu.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
  background: var(--primary-bg-color) !important;
}

.dark-mode.light-menu.hover-submenu1.sidebar-mini.sidenav-toggled .slide-menu {
  background: #fff !important;
}

.dark-mode .main-container .input-color-picker {
  background-color: var(--dark-theme);
}
.dark-mode .main-container .input-dark-color-picker {
  background-color: var(--dark-theme);
}
.dark-mode .main-container .input-transparent-color-picker {
  background-color: var(--dark-theme);
}

.color-header.color-menu.sidenav-toggled .header-brand-img.light-logo {
  display: none !important;
}

.header-light.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.header-light.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: block !important;
}

.color-menu .sub-slide-item,
.color-menu .sub-slide-item2 {
  color: rgba(255, 255, 255, 0.9);
}

.dark-mode.header-light .app-sidebar__toggle {
  color: #495584 !important;
}

.switch-toggle .bg-img1 #bgimage1 {
  width: 40px;
  height: 40px;
}
.switch-toggle .bg-img2 #bgimage2 {
  width: 40px;
  height: 40px;
}
.switch-toggle .bg-img3 #bgimage3 {
  width: 40px;
  height: 40px;
}
.switch-toggle .bg-img4 #bgimage4 {
  width: 40px;
  height: 40px;
}

.switch_section p {
  margin-bottom: 0px;
}

.dark-mode.light-menu.header-light.scrollable-layout .app-sidebar {
  border-right: 1px solid #e6e6e6;
}

.dark-mode.color-menu .side-menu .sub-side-menu__label,
.dark-mode.color-menu .side-menu .sub-side-menu__label2 {
  color: #fff !important;
}

.dark-mode.gradient-menu .side-menu .sub-side-menu__label,
.dark-mode.gradient-menu .side-menu .sub-side-menu__label2 {
  color: #fff !important;
}

.dark-mode.header-light .country span:hover {
  color: var(--primary-bg-color) !important;
}

.dark-mode.header-light .app-header .header-right-icons .nav-link.icon:hover {
  color: var(--primary-bg-color) !important;
}

.dark-mode.color-menu .app-sidebar .side-menu .slide a.active {
  opacity: 1;
  color: #fff !important;
}

.dark-mode.light-menu .side-menu .slide a.active .sub-side-menu__label {
  color: var(--primary-bg-color) !important;
}

.dark-mode .side-menu .slide a.active .sub-side-menu__label {
  color: var(--primary-bg-color);
}

.dark-mode.gradient-menu .app-sidebar .side-menu .slide a.active {
  opacity: 1;
  color: #fff !important;
}

@media (min-width: 992px) {
  .color-menu .main-sidemenu .slide-left,
  .color-menu .main-sidemenu .slide-right {
    color: #fff;
    border: 1px solid var(--dark-border);
    background: var(--primary-bg-color);
  }
  .color-menu .main-sidemenu .slide-left svg,
  .color-menu .main-sidemenu .slide-right svg {
    fill: #fff;
  }
  .dark-menu .main-sidemenu .slide-left,
  .dark-menu .main-sidemenu .slide-right {
    color: #fff;
    border: 1px solid var(--dark-border);
    background: var(--dark-theme);
  }
  .dark-menu .main-sidemenu .slide-left svg,
  .dark-menu .main-sidemenu .slide-right svg {
    fill: #fff;
  }
  .gradient-menu .main-sidemenu .slide-left,
  .gradient-menu .main-sidemenu .slide-right {
    color: #fff;
    border: 1px solid var(--dark-border);
    background: var(--primary-bg-color);
  }
  .gradient-menu .main-sidemenu .slide-left svg,
  .gradient-menu .main-sidemenu .slide-right svg {
    fill: #fff;
  }
  .main-sidemenu .slide-left,
  .main-sidemenu .slide-right {
    background: #fff;
    border: 1px solid #eaedf1;
  }
  .main-sidemenu .slide-left svg,
  .main-sidemenu .slide-right svg {
    fill: #495057;
  }
  .dark-mode .main-sidemenu .slide-left,
  .dark-mode .main-sidemenu .slide-right {
    background: var(--dark-body);
    border: 1px solid var(--dark-border);
  }
  .dark-mode .main-sidemenu .slide-left svg,
  .dark-mode .main-sidemenu .slide-right svg {
    fill: #fff;
  }
  .dark-mode.color-menu .main-sidemenu .slide-left, .dark-mode.color-menu .main-sidemenu .slide-right {
    background: var(--primary-bg-color);
  }
  .dark-mode.gradient-menu .main-sidemenu .slide-left, .dark-mode.gradient-menu .main-sidemenu .slide-right {
    background: var(--primary-bg-color);
  }
  .dark-mode.light-menu .main-sidemenu .slide-left, .dark-mode.light-menu .main-sidemenu .slide-right {
    background: #fff;
    border: 1px solid #eaedf1;
  }
  .dark-mode.light-menu .main-sidemenu .slide-left svg, .dark-mode.light-menu .main-sidemenu .slide-right svg {
    fill: #495057;
  }
}
@media (max-width: 991px) {
  .dark-mode.color-header .header.hor-header {
    box-shadow: 0 8px 24px transparent;
  }
  .dark-mode.dark-header .header.hor-header {
    box-shadow: 0 8px 24px transparent;
  }
  .dark-mode.gradient-header .header.hor-header {
    box-shadow: 0 8px 24px transparent;
  }
}
@media (max-width: 992px) {
  .horizontal.dark-mode.sidenav-toggled .header.hor-header {
    box-shadow: none;
  }
}
.dark-mode.bg-img1 .form_holder {
  background: var(--dark-body) !important;
}
@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .dark-mode.bg-img1 .form_holder {
    background: var(--dark-theme) !important;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
}
.dark-mode.bg-img2 .form_holder {
  background: var(--dark-body) !important;
}
@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .dark-mode.bg-img2 .form_holder {
    background: var(--dark-theme) !important;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
}
.dark-mode.bg-img3 .form_holder {
  background: var(--dark-body) !important;
}
@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .dark-mode.bg-img3 .form_holder {
    background: var(--dark-theme) !important;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
}
.dark-mode.bg-img4 .form_holder {
  background: var(--dark-body) !important;
}
@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .dark-mode.bg-img4 .form_holder {
    background: var(--dark-theme) !important;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
}

@media (min-width: 992px) {
  .hover-submenu.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__icon {
    color: #282f53 !important;
    fill: #282f53 !important;
  }
  .hover-submenu1.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__icon {
    color: #282f53 !important;
    fill: #282f53 !important;
  }
  .hover-submenu.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item:hover .side-menu__icon,
  .hover-submenu.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item:focus .side-menu__icon {
    color: var(--primary-bg-color) !important;
    fill: var(--primary-bg-color) !important;
  }
  .hover-submenu1.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item:hover .side-menu__icon,
  .hover-submenu1.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item:focus .side-menu__icon {
    color: var(--primary-bg-color) !important;
    fill: var(--primary-bg-color) !important;
  }
  .hover-submenu.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item.active .side-menu__icon {
    color: var(--primary-bg-color) !important;
    fill: var(--primary-bg-color) !important;
  }
  .hover-submenu1.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open.light-menu .side-menu__item.active .side-menu__icon {
    color: var(--primary-bg-color) !important;
    fill: var(--primary-bg-color) !important;
  }
}
.color-menu .side-menu .side-menu__item.active {
  color: #fff !important;
}

@media (min-width: 992px) {
  .rtl.layout-boxed.hover-submenu.sidebar-mini.sidenav-toggled .app-sidebar {
    right: -1px !important;
  }
  .rtl.layout-boxed.hover-submenu1.sidebar-mini.sidenav-toggled .app-sidebar {
    right: -1px !important;
  }
}
.dark-mode.color-menu .sub-slide-item.active,
.dark-mode.color-menu .sub-slide-item:hover,
.dark-mode.color-menu .sub-slide-item:focus,
.dark-mode.color-menu .sub-slide-item2.active,
.dark-mode.color-menu .sub-slide-item2:hover,
.dark-mode.color-menu .sub-slide-item2:focus,
.dark-mode.color-menu .sub-side-menu__label.active,
.dark-mode.color-menu .sub-side-menu__label:hover,
.dark-mode.color-menu .sub-side-menu__label:focus,
.dark-mode.color-menu .sub-side-menu__label1.active,
.dark-mode.color-menu .sub-side-menu__label1:hover,
.dark-mode.color-menu .sub-side-menu__label1:focus,
.dark-mode.color-menu .sub-side-menu__label2.active,
.dark-mode.color-menu .sub-side-menu__label2:hover,
.dark-mode.color-menu .sub-side-menu__label2:focus {
  color: #fff !important;
}

@media (min-width: 768px) {
  .color-header .nav-link.icon svg,
  .dark-header .nav-link.icon svg,
  .gradient-header .nav-link.icon svg {
    fill: #fff !important;
  }
  .color-header .header .nav-link.icon:hover svg,
  .color-header .header .nav-link.icon:focus svg,
  .dark-header .header .nav-link.icon:hover svg,
  .dark-header .header .nav-link.icon:focus svg,
  .gradient-header .header .nav-link.icon:hover svg,
  .gradient-header .header .nav-link.icon:focus svg {
    color: #fff !important;
    fill: #fff !important;
  }
  .color-header .app .header .nav-link #profile-heading,
  .dark-header .app .header .nav-link #profile-heading,
  .gradient-header .app .header .nav-link #profile-heading {
    color: #fff !important;
  }
  .color-header .main-header-center .btn svg,
  .dark-header .main-header-center .btn svg,
  .gradient-header .main-header-center .btn svg {
    fill: #fff !important;
  }
}
@media (max-width: 767.98px) {
  .dark-mode.dark-header .navbar-toggler-icon.fe.fe-more-vertical,
  .dark-mode.color-header .navbar-toggler-icon.fe.fe-more-vertical,
  .dark-mode.gradient-header .navbar-toggler-icon.fe.fe-more-vertical,
  .light-mode.dark-header .navbar-toggler-icon.fe.fe-more-vertical,
  .light-mode.color-header .navbar-toggler-icon.fe.fe-more-vertical,
  .light-mode.gradient-header .navbar-toggler-icon.fe.fe-more-vertical {
    color: #fff !important;
  }
  .dark-mode.dark-header .demo-icon.nav-link.icon svg,
  .dark-mode.color-header .demo-icon.nav-link.icon svg,
  .dark-mode.gradient-header .demo-icon.nav-link.icon svg,
  .light-mode.dark-header .demo-icon.nav-link.icon svg,
  .light-mode.color-header .demo-icon.nav-link.icon svg,
  .light-mode.gradient-header .demo-icon.nav-link.icon svg {
    fill: #fff !important;
  }
}
@media (max-width: 991.98px) {
  .dark-mode.light-menu.app.sidebar-mini.sidebar-gone.sidenav-toggled.light-menu .side-menu__icon {
    color: #495584 !important;
    fill: #495584 !important;
  }
  .dark-mode.light-menu .side-menu h3 {
    color: #76839a !important;
  }
}
.dark-mode.light-menu .side-menu__item.active .side-menu__icon {
  color: var(--primary-bg-color) !important;
  fill: var(--primary-bg-color) !important;
}
.dark-mode.light-menu .side-menu__item:hover {
  color: var(--primary-bg-color) !important;
}
.dark-mode.light-menu .slide-menu li:hover .slide-item:before {
  color: var(--primary-bg-color) !important;
}
.dark-mode.light-menu .sub-slide .sub-side-menu__item:hover {
  color: var(--primary-bg-color) !important;
}
.dark-mode.light-menu .slide .slide-menu .sub-slide:hover .sub-side-menu__item {
  color: var(--primary-bg-color) !important;
}
.dark-mode.light-menu .slide .slide-menu .sub-slide:hover .sub-side-menu__item .sub-side-menu__label {
  color: var(--primary-bg-color) !important;
}
.dark-mode.light-menu .slide .slide-menu .sub-slide .sub-slide-menu .sub-slide2:hover .sub-side-menu__item2 {
  color: var(--primary-bg-color) !important;
}
.dark-mode.light-menu .slide .slide-menu .sub-slide .sub-slide-menu .sub-slide2:hover .sub-side-menu__item2 .sub-side-menu__label2 {
  color: var(--primary-bg-color) !important;
}

.dark-mode.dark-menu .slide-menu li .slide-item:before {
  color: #fff !important;
}
.dark-mode.dark-menu .app-sidebar .side-menu .slide a:hover.slide-item:before {
  color: var(--primary-bg-color) !important;
}

.dark-mode.header-light .main-header-center .btn svg {
  fill: #495584 !important;
}
.dark-mode.header-light .main-header-center .form-control::-moz-placeholder {
  color: #212529 !important;
}
.dark-mode.header-light .main-header-center .form-control::placeholder {
  color: #212529 !important;
}
.dark-mode.header-light .nav-link.icon svg {
  fill: #495584 !important;
}
.dark-mode.header-light .header .nav-link #profile-heading, .dark-mode.header-light .header .nav-link #profile-heading:hover, .dark-mode.header-light .header .nav-link #profile-heading i {
  color: #495584 !important;
}

@media (max-width: 991.98px) {
  .dark-mode.color-header .nav-link.icon svg {
    fill: #fff !important;
    color: #fff !important;
  }
  .light-mode.light-menu .side-menu__icon {
    color: #495584 !important;
    fill: #495584 !important;
  }
  .light-mode.light-menu .side-menu__item.active .side-menu__icon {
    color: var(--primary-bg-color) !important;
    fill: var(--primary-bg-color) !important;
  }
  .dark-mode.color-menu .slide-menu li .slide-item:before,
  .dark-mode.dark-menu .slide-menu li .slide-item:before,
  .dark-mode.gradient-menu .slide-menu li .slide-item:before,
  .light-mode.color-menu .slide-menu li .slide-item:before,
  .light-mode.dark-menu .slide-menu li .slide-item:before,
  .light-mode.gradient-mode .slide-menu li .slide-item:before,
  .color-menu .slide-menu li .slide-item:before,
  .dark-menu .slide-menu li .slide-item:before,
  .gradient-menu .slide-menu li .slide-item:before {
    color: #fff !important;
  }
}
@media (max-width: 767.98px) {
  .dark-mode.header-light .navbar-toggler-icon.fe.fe-more-vertical {
    color: #495584 !important;
    fill: #495584 !important;
  }
  .dark-mode.header-light .nav-link.icon svg {
    fill: #495584 !important;
  }
  .dark-mode.header-light .responsive-navbar .navbar-collapse {
    background-color: #fff !important;
  }
}
.dark-mode.horizontal.header-light .hor-header .header-brand-img.desktop-logo {
  display: none !important;
}
.dark-mode.horizontal.header-light .hor-header .header-brand-img.light-logo1 {
  display: block !important;
}

@media (min-width: 992px) {
  .header-light.dark-mode.sidebar-mini .header-brand-img.desktop-logo,
  .app.dark-mode.header-light.sidenav-toggled.sidenav-toggled-open .header-brand-img.desktop-logo {
    display: block !important;
  }
  .header-light.dark-mode.sidebar-mini .header-brand-img.light-logo1,
  .header-light.dark-mode.sidebar-mini .header-brand-img.toggle-logo,
  .header-light.dark-mode.sidebar-mini .header-brand-img.light-logo,
  .app.dark-mode.header-light.sidenav-toggled.sidenav-toggled-open .header-brand-img.light-logo1,
  .app.dark-mode.header-light.sidenav-toggled.sidenav-toggled-open .header-brand-img.toggle-logo,
  .app.dark-mode.header-light.sidenav-toggled.sidenav-toggled-open .header-brand-img.light-logo {
    display: none !important;
  }
}
.app.dark-mode.header-light.sidenav-toggled .header-brand-img.desktop-logo {
  display: none !important;
}
.app.dark-mode.header-light.sidenav-toggled .header-brand-img.toggle-logo {
  display: block !important;
}

@media (min-width: 992px) {
  .rtl.closed-leftmenu.app.sidebar-mini.sidenav-toggled .app-header.header {
    padding-left: 20px !important;
    padding-right: inherit !important;
  }
}
.horizontal.dark-mode.gradient-menu .app-sidebar {
  background: linear-gradient(to right, var(--primary-bg-color) 0%, #21bc7c 100%) !important;
}
.horizontal.dark-mode.color-menu .app-sidebar.horizontal-main {
  background-color: var(--primary-bg-color) !important;
}
.horizontal.dark-mode.light-menu .app-sidebar.horizontal-main {
  background-color: #fff !important;
}

.app.dark-mode.dark-menu.header-light .side-header .header-brand1 .desktop-logo,
.app.dark-mode.header-light.gradient-menu .side-header .header-brand1 .desktop-logo,
.app.dark-mode.header-light.gradient-menu.sidenav-toggled.sidenav-toggled-open .side-header .header-brand1 .desktop-logo {
  display: block !important;
}
.app.dark-mode.dark-menu.header-light .side-header .header-brand1 .light-logo,
.app.dark-mode.dark-menu.header-light .side-header .header-brand1 .toggle-logo,
.app.dark-mode.dark-menu.header-light .side-header .header-brand1 .light-logo1,
.app.dark-mode.header-light.gradient-menu .side-header .header-brand1 .light-logo,
.app.dark-mode.header-light.gradient-menu .side-header .header-brand1 .toggle-logo,
.app.dark-mode.header-light.gradient-menu .side-header .header-brand1 .light-logo1,
.app.dark-mode.header-light.gradient-menu.sidenav-toggled.sidenav-toggled-open .side-header .header-brand1 .light-logo,
.app.dark-mode.header-light.gradient-menu.sidenav-toggled.sidenav-toggled-open .side-header .header-brand1 .toggle-logo,
.app.dark-mode.header-light.gradient-menu.sidenav-toggled.sidenav-toggled-open .side-header .header-brand1 .light-logo1 {
  display: none !important;
}

.app.dark-mode.header-light.gradient-menu.sidenav-toggled .side-header .header-brand1 .toggle-logo {
  display: block !important;
}
.app.dark-mode.header-light.gradient-menu.sidenav-toggled .side-header .header-brand1 .light-logo,
.app.dark-mode.header-light.gradient-menu.sidenav-toggled .side-header .header-brand1 .desktop-logo,
.app.dark-mode.header-light.gradient-menu.sidenav-toggled .side-header .header-brand1 .light-logo1 {
  display: none !important;
}

.app.color-menu.layout-boxed.fixed-layout .slide-menu li .slide-item:before,
.color-menu .slide-menu li .slide-item:before {
  color: #fff !important;
}

.app.sidebar-mini.color-menu .slide-menu li .slide-item:before,
.app.sidebar-mini.dark-menu .slide-menu li .slide-item:before,
.app.sidebar-mini.gradient-menu .slide-menu li .slide-item:before {
  color: #fff !important;
}

.app.sidebar-mini.hover-submenu1.sidenav-toggled.rtl.sidenav-toggled-open .slide .slide-menu {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media (min-width: 768px) {
  .app.dark-header.color-menu.sidenav-toggled .side-menu__item.active .side-menu__icon {
    color: #fff !important;
    fill: #fff !important;
  }
}
@media (max-width: 991.98px) {
  .horizontal.app.sidenav-toggled .side-menu .side-menu__icon {
    color: #495584 !important;
    fill: #495584 !important;
  }
  .horizontal.dark-mode.app.sidenav-toggled .side-menu .side-menu__icon {
    color: #fff !important;
    fill: #fff !important;
  }
}
.app.horizontal .slide-item.active:before,
.app.horizontal .slide-item:hover:before,
.app.horizontal .slide-item:focus:before {
  color: var(--primary-bg-color) !important;
}

@media (max-width: 991.98px) {
  .app.dark-mode.icontext-menu.sidebar-gone .logo-horizontal .desktop-logo {
    display: block !important;
  }
  .app.dark-mode.icontext-menu.sidebar-gone .logo-horizontal .light-logo1 {
    display: none !important;
  }
}
.app.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
  display: block !important;
}
.app.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo,
.app.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo1,
.app.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
  display: none !important;
}

.dark-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
  display: block !important;
}
.dark-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo,
.dark-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo1,
.dark-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
  display: none !important;
}

.dark-menu .app-sidebar .slide-menu {
  background-color: #2c2f3e !important;
}
.dark-menu .slide-item:before {
  color: #fff !important;
}
.dark-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: block !important;
}
.dark-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo,
.dark-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1,
.dark-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
  display: none !important;
}

.app.dark-mode.light-menu .slide-menu li .slide-item:before {
  color: #495057 !important;
}
.app.dark-mode.light-menu .slide-menu li:hover .slide-item:before {
  color: var(--primary-bg-color) !important;
}

.app.light-menu.header-light.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo,
.app.light-menu.header-light.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo,
.app.light-menu.header-light.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
  display: none !important;
}
.app.light-menu.header-light.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: block !important;
}

.app.color-menu.header-light .app-sidebar .side-header .header-brand-img.light-logo1,
.app.color-menu.header-light .app-sidebar .side-header .header-brand-img.light-logo,
.app.color-menu.header-light .app-sidebar .side-header .header-brand-img.toggle-logo,
.app.color-menu.header-light.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1,
.app.color-menu.header-light.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo,
.app.color-menu.header-light.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo,
.app.dark-menu.header-light.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1,
.app.dark-menu.header-light.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo,
.app.dark-menu.header-light.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
  display: none !important;
}
.app.color-menu.header-light .app-sidebar .side-header .header-brand-img.desktop-logo,
.app.color-menu.header-light.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo,
.app.dark-menu.header-light.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: block !important;
}

.app.color-menu.header-light.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo1,
.app.color-menu.header-light.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo,
.app.color-menu.header-light.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo,
.app.dark-menu.header-light.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo1,
.app.dark-menu.header-light.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo,
.app.dark-menu.header-light.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: none !important;
}
.app.color-menu.header-light.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo,
.app.dark-menu.header-light.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
  display: block !important;
}

@media (min-width: 992px) {
  .rtl.hover-submenu1.dark-mode.app.sidenav-toggled.light-menu .side-header,
  .rtl.hover-submenu1.dark-mode.app.sidenav-toggled.sidenav-toggled-open.light-menu .side-header,
  .rtl.hover-submenu.dark-mode.app.sidenav-toggled.light-menu .side-header,
  .rtl.hover-submenu.dark-mode.app.sidenav-toggled.sidenav-toggled-open.light-menu .side-header {
    border-left: 1px solid #eaedf1 !important;
    border-right: 0px !important;
  }
  .app.rtl.dark-mode.sidenav-toggled.color-menu.hover-submenu.color-header .side-header {
    border-left: 1px solid var(--dark-border) !important;
    border-right: inherit !important;
  }
}
@media (max-width: 992px) {
  .app.color-header .navbar-toggler-icon.fe.fe-more-vertical,
  .app.dark-header .navbar-toggler-icon.fe.fe-more-vertical {
    color: #fff !important;
  }
  .app.color-header .demo-icon svg,
  .app.dark-header .demo-icon svg {
    fill: #fff !important;
  }
}
@media (min-width: 1400px) {
  body.dark-mode.layout-boxed.bg-img1 .page {
    background: url(../images/media/bg-img1.jpg);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    content: "";
    left: 0;
    right: 0;
    top: 0;
  }
  body.dark-mode.layout-boxed.bg-img1 .page .app-header {
    background-color: transparent !important;
  }
  body.dark-mode.layout-boxed.bg-img1 .page .app-header.fixed-header {
    background-color: var(--dark-body) !important;
  }
  @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    body.dark-mode.layout-boxed.bg-img1 .page .app-header {
      background-color: transparent !important;
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
    }
    body.dark-mode.layout-boxed.bg-img1 .page .app-header.fixed-header {
      background-color: transparent !important;
    }
  }
  body.dark-mode.layout-boxed.bg-img1 .page .app-sidebar {
    background-color: transparent !important;
  }
  @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    body.dark-mode.layout-boxed.bg-img1 .page .app-sidebar {
      background-color: transparent !important;
      backdrop-filter: inherit;
      -webkit-backdrop-filter: blur(20px);
    }
  }
  body.dark-mode.layout-boxed.bg-img2 .page {
    background: url(../images/media/bg-img2.jpg);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    content: "";
    left: 0;
    right: 0;
    top: 0;
  }
  body.dark-mode.layout-boxed.bg-img2 .page .app-header {
    background-color: transparent !important;
  }
  body.dark-mode.layout-boxed.bg-img2 .page .app-header.fixed-header {
    background-color: var(--dark-body) !important;
  }
  @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    body.dark-mode.layout-boxed.bg-img2 .page .app-header {
      background-color: transparent !important;
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
    }
    body.dark-mode.layout-boxed.bg-img2 .page .app-header.fixed-header {
      background-color: transparent !important;
    }
  }
  body.dark-mode.layout-boxed.bg-img2 .page .app-sidebar {
    background-color: transparent !important;
  }
  @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    body.dark-mode.layout-boxed.bg-img2 .page .app-sidebar {
      background-color: transparent !important;
      backdrop-filter: inherit;
      -webkit-backdrop-filter: blur(20px);
    }
  }
  body.dark-mode.layout-boxed.bg-img3 .page {
    background: url(../images/media/bg-img3.jpg);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    content: "";
    left: 0;
    right: 0;
    top: 0;
  }
  body.dark-mode.layout-boxed.bg-img3 .page .app-header {
    background-color: transparent !important;
  }
  body.dark-mode.layout-boxed.bg-img3 .page .app-header.fixed-header {
    background-color: var(--dark-body) !important;
  }
  @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    body.dark-mode.layout-boxed.bg-img3 .page .app-header {
      background-color: transparent !important;
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
    }
    body.dark-mode.layout-boxed.bg-img3 .page .app-header.fixed-header {
      background-color: transparent !important;
    }
  }
  body.dark-mode.layout-boxed.bg-img3 .page .app-sidebar {
    background-color: transparent !important;
  }
  @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    body.dark-mode.layout-boxed.bg-img3 .page .app-sidebar {
      background-color: transparent !important;
      backdrop-filter: inherit;
      -webkit-backdrop-filter: blur(20px);
    }
  }
  body.dark-mode.layout-boxed.bg-img4 .page {
    background: url(../images/media/bg-img4.jpg);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    content: "";
    left: 0;
    right: 0;
    top: 0;
  }
  body.dark-mode.layout-boxed.bg-img4 .page .app-header {
    background-color: transparent !important;
  }
  body.dark-mode.layout-boxed.bg-img4 .page .app-header.fixed-header {
    background-color: var(--dark-body) !important;
  }
  @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    body.dark-mode.layout-boxed.bg-img4 .page .app-header {
      background-color: transparent !important;
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
    }
    body.dark-mode.layout-boxed.bg-img4 .page .app-header.fixed-header {
      background-color: transparent !important;
    }
  }
  body.dark-mode.layout-boxed.bg-img4 .page .app-sidebar {
    background-color: transparent !important;
  }
  @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    body.dark-mode.layout-boxed.bg-img4 .page .app-sidebar {
      background-color: transparent !important;
      backdrop-filter: inherit;
      -webkit-backdrop-filter: blur(20px);
    }
  }
}
.app.sidebar-mini.gradient-header.sidenav-toggled.sidenav-toggled-open.light-menu.icon-overlay .app-sidebar .side-header .header-brand-img.light-logo1,
.app.sidebar-mini.color-header.sidenav-toggled.sidenav-toggled-open.light-menu.icon-overlay .app-sidebar .side-header .header-brand-img.light-logo1,
.app.sidebar-mini.dark-header.sidenav-toggled.sidenav-toggled-open.light-menu.icon-overlay .app-sidebar .side-header .header-brand-img.light-logo1 {
  display: block !important;
}
.app.sidebar-mini.gradient-header.sidenav-toggled.sidenav-toggled-open.light-menu.icon-overlay .app-sidebar .side-header .header-brand-img.light-logo,
.app.sidebar-mini.gradient-header.sidenav-toggled.sidenav-toggled-open.light-menu.icon-overlay .app-sidebar .side-header .header-brand-img.desktop-logo,
.app.sidebar-mini.gradient-header.sidenav-toggled.sidenav-toggled-open.light-menu.icon-overlay .app-sidebar .side-header .header-brand-img.toggle-logo,
.app.sidebar-mini.color-header.sidenav-toggled.sidenav-toggled-open.light-menu.icon-overlay .app-sidebar .side-header .header-brand-img.light-logo,
.app.sidebar-mini.color-header.sidenav-toggled.sidenav-toggled-open.light-menu.icon-overlay .app-sidebar .side-header .header-brand-img.desktop-logo,
.app.sidebar-mini.color-header.sidenav-toggled.sidenav-toggled-open.light-menu.icon-overlay .app-sidebar .side-header .header-brand-img.toggle-logo,
.app.sidebar-mini.dark-header.sidenav-toggled.sidenav-toggled-open.light-menu.icon-overlay .app-sidebar .side-header .header-brand-img.light-logo,
.app.sidebar-mini.dark-header.sidenav-toggled.sidenav-toggled-open.light-menu.icon-overlay .app-sidebar .side-header .header-brand-img.desktop-logo,
.app.sidebar-mini.dark-header.sidenav-toggled.sidenav-toggled-open.light-menu.icon-overlay .app-sidebar .side-header .header-brand-img.toggle-logo {
  display: none !important;
}

@media (min-width: 992px) {
  .horizontal.dark-mode .app-sidebar.horizontal-main {
    border-bottom: 1px solid var(--dark-border) !important;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    background-color: var(--dark-body) !important;
  }
  @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    .horizontal.dark-mode .app-sidebar.horizontal-main {
      background-color: transparent !important;
      backdrop-filter: blur(20px) !important;
      -webkit-backdrop-filter: blur(20px) !important;
    }
  }
}
@media (max-width: 991.98px) {
  .horizontal.app.sidenav-toggled.color-menu .side-menu .side-menu__icon,
  .horizontal.app.sidenav-toggled.dark-menu .side-menu .side-menu__icon,
  .horizontal.app.sidenav-toggled.gradient-menu .side-menu .side-menu__icon {
    color: #fff !important;
    fill: #fff !important;
  }
}
.horizontal.color-menu .slide-item.active:before,
.horizontal.color-menu .slide-item:hover:before,
.horizontal.color-menu .slide-item:focus:before,
.horizontal.gradient-menu .slide-item.active:before,
.horizontal.gradient-menu .slide-item:hover:before,
.horizontal.gradient-menu .slide-item:focus:before {
  color: #fff !important;
}

.dark-mode.horizontal.light-menu .sticky.stickyClass .app-sidebar {
  background-color: #fff !important;
}

.dark-mode.horizontal.color-menu .sticky.stickyClass .app-sidebar {
  background-color: var(--primary-bg-color) !important;
}

.dark-mode.horizontal.gradient-menu .sticky.stickyClass .app-sidebar {
  background-color: var(--primary-bg-color) !important;
}

@media (max-width: 991.98px) {
  .horizontal.dark-mode.light-menu.app.sidenav-toggled .side-menu .side-menu__icon {
    color: #495584 !important;
    fill: #495584 !important;
  }
  .horizontal.dark-mode.light-menu.app.sidenav-toggled .side-menu__item:hover .side-menu__icon {
    color: var(--primary-bg-color) !important;
    fill: var(--primary-bg-color) !important;
  }
}
@media (max-width: 991.98px) {
  .rtl.horizontal .slide-menu li .slide-item:before {
    margin-left: 8px;
    margin-right: inherit;
  }
  .rtl.horizontal .slide-menu a:before {
    margin-left: 10px;
    margin-right: inherit;
  }
}
body.bg-img1 .menu-styles,
body.bg-img1 .header-styles,
body.bg-img2 .menu-styles,
body.bg-img2 .header-styles,
body.bg-img3 .menu-styles,
body.bg-img3 .header-styles,
body.bg-img4 .menu-styles,
body.bg-img4 .header-styles {
  display: none !important;
}

.app.light-menu.gradient-header.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header .light-logo1,
.app.sidebar-mini.dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .side-header .light-logo1,
.app.dark-mode.light-menu.gradient-header.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header .light-logo1,
.app.sidebar-mini.light-menu.sidenav-toggled.dark-header.sidenav-toggled-open .side-header .light-logo1,
.app.sidebar-mini.light-menu.sidenav-toggled.color-header.sidenav-toggled-open .side-header .light-logo1 {
  display: block !important;
}
.app.light-menu.gradient-header.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header .light-logo,
.app.light-menu.gradient-header.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header .desktop-logo,
.app.light-menu.gradient-header.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header .toggle-logo,
.app.sidebar-mini.dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .side-header .light-logo,
.app.sidebar-mini.dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .side-header .desktop-logo,
.app.sidebar-mini.dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .side-header .toggle-logo,
.app.dark-mode.light-menu.gradient-header.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header .light-logo,
.app.dark-mode.light-menu.gradient-header.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header .desktop-logo,
.app.dark-mode.light-menu.gradient-header.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header .toggle-logo,
.app.sidebar-mini.light-menu.sidenav-toggled.dark-header.sidenav-toggled-open .side-header .light-logo,
.app.sidebar-mini.light-menu.sidenav-toggled.dark-header.sidenav-toggled-open .side-header .desktop-logo,
.app.sidebar-mini.light-menu.sidenav-toggled.dark-header.sidenav-toggled-open .side-header .toggle-logo,
.app.sidebar-mini.light-menu.sidenav-toggled.color-header.sidenav-toggled-open .side-header .light-logo,
.app.sidebar-mini.light-menu.sidenav-toggled.color-header.sidenav-toggled-open .side-header .desktop-logo,
.app.sidebar-mini.light-menu.sidenav-toggled.color-header.sidenav-toggled-open .side-header .toggle-logo {
  display: none !important;
}

.app.sidebar-mini.rtl.dark-mode.header-light.light-menu.sidenav-toggled .side-header .light-logo {
  display: block !important;
}
.app.sidebar-mini.rtl.dark-mode.header-light.light-menu.sidenav-toggled .side-header .light-logo1,
.app.sidebar-mini.rtl.dark-mode.header-light.light-menu.sidenav-toggled .side-header .desktop-logo,
.app.sidebar-mini.rtl.dark-mode.header-light.light-menu.sidenav-toggled .side-header .toggle-logo {
  display: none !important;
}

@media (min-width: 767px) {
  .color-menu.sidebar-mini.sidenav-toggled .side-menu__item.active .side-menu__icon {
    color: #fff !important;
    fill: #fff !important;
  }
}
@media (max-width: 991.98px) {
  .app.sidebar-mini.dark-mode.icontext-menu .app-sidebar,
  .app.sidebar-mini.dark-mode.closed-leftmenu .app-sidebar {
    border-right: 1px solid var(--dark-border) !important;
  }
}
.horizontal.gradient-menu .app-sidebar {
  background: linear-gradient(to right, var(--primary-bg-color) 0%, #21bc7c 100%) !important;
}

@media (min-width: 992px) {
  .dark-mode.bg-img1 .main-sidemenu .slide-left,
  .dark-mode.bg-img1 .main-sidemenu .slide-right,
  .dark-mode.bg-img2 .main-sidemenu .slide-left,
  .dark-mode.bg-img2 .main-sidemenu .slide-right,
  .dark-mode.bg-img3 .main-sidemenu .slide-left,
  .dark-mode.bg-img3 .main-sidemenu .slide-right,
  .dark-mode.bg-img4 .main-sidemenu .slide-left,
  .dark-mode.bg-img4 .main-sidemenu .slide-right {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}
.gradient-header.gradient-menu.app .header .nav-link #profile-heading:hover, .gradient-header.gradient-menu.app .header .nav-link #profile-heading:focus {
  color: #fff !important;
  fill: #fff;
}

@media (min-width: 992px) {
  .rtl.app.dark-mode.dark-header.dark-menu .side-header {
    border-left: 1px solid var(--dark-border) !important;
    border-right: inherit;
  }
  .app.sidebar-mini.dark-mode.rtl.header-light.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand1 .header-brand-img.desktop-logo,
  .app.sidebar-mini.dark-mode.rtl.header-light.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand1 .header-brand-img.toggle-logo,
  .app.sidebar-mini.dark-mode.rtl.header-light.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand1 .header-brand-img.light-logo,
  .app.sidebar-mini.dark-mode.light-menu.rtl.default-menu.gradient-header.layout-boxed.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand1 .header-brand-img.desktop-logo,
  .app.sidebar-mini.dark-mode.light-menu.rtl.default-menu.gradient-header.layout-boxed.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand1 .header-brand-img.toggle-logo,
  .app.sidebar-mini.dark-mode.light-menu.rtl.default-menu.gradient-header.layout-boxed.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand1 .header-brand-img.light-logo {
    display: none !important;
  }
  .app.sidebar-mini.dark-mode.rtl.header-light.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand1 .header-brand-img.light-logo1,
  .app.sidebar-mini.dark-mode.light-menu.rtl.default-menu.gradient-header.layout-boxed.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand1 .header-brand-img.light-logo1 {
    display: block !important;
  }
  .app.sidebar-mini.dark-mode.light-menu.rtl.default-menu.gradient-header.layout-boxed.sidenav-toggled .app-sidebar .side-header .header-brand1 .header-brand-img.desktop-logo,
  .app.sidebar-mini.dark-mode.light-menu.rtl.default-menu.gradient-header.layout-boxed.sidenav-toggled .app-sidebar .side-header .header-brand1 .header-brand-img.toggle-logo,
  .app.sidebar-mini.dark-mode.light-menu.rtl.default-menu.gradient-header.layout-boxed.sidenav-toggled .app-sidebar .side-header .header-brand1 .header-brand-img.light-logo1 {
    display: none !important;
  }
  .app.sidebar-mini.dark-mode.light-menu.rtl.default-menu.gradient-header.layout-boxed.sidenav-toggled .app-sidebar .side-header .header-brand1 .header-brand-img.light-logo {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .rtl.dark-mode.header-light.light-menu.app.icontext-menu.sidenav-toggled.sidenav-toggled-open .side-header {
    border-bottom: 1px solid #eaedf1 !important;
  }
  .app.sidebar-mini.dark-mode.hover-submenu.sidenav-toggled.sidenav-toggled-open.header-light.light-menu.rtl .app-sidebar .side-header .header-brand1 .header-brand-img.desktop-logo,
  .app.sidebar-mini.dark-mode.hover-submenu.sidenav-toggled.sidenav-toggled-open.header-light.light-menu.rtl .app-sidebar .side-header .header-brand1 .header-brand-img.toggle-logo,
  .app.sidebar-mini.dark-mode.hover-submenu.sidenav-toggled.sidenav-toggled-open.header-light.light-menu.rtl .app-sidebar .side-header .header-brand1 .header-brand-img.light-logo1,
  .app.sidebar-mini.dark-mode.hover-submenu1.sidenav-toggled.sidenav-toggled-open.header-light.light-menu.rtl .app-sidebar .side-header .header-brand1 .header-brand-img.desktop-logo,
  .app.sidebar-mini.dark-mode.hover-submenu1.sidenav-toggled.sidenav-toggled-open.header-light.light-menu.rtl .app-sidebar .side-header .header-brand1 .header-brand-img.toggle-logo,
  .app.sidebar-mini.dark-mode.hover-submenu1.sidenav-toggled.sidenav-toggled-open.header-light.light-menu.rtl .app-sidebar .side-header .header-brand1 .header-brand-img.light-logo1 {
    display: none !important;
  }
  .app.sidebar-mini.dark-mode.hover-submenu.sidenav-toggled.sidenav-toggled-open.header-light.light-menu.rtl .app-sidebar .side-header .header-brand1 .header-brand-img.light-logo,
  .app.sidebar-mini.dark-mode.hover-submenu1.sidenav-toggled.sidenav-toggled-open.header-light.light-menu.rtl .app-sidebar .side-header .header-brand1 .header-brand-img.light-logo {
    display: block !important;
  }
}
.horizontal.color-header .header .nav-link #profile-heading:hover,
.horizontal.color-header .header .nav-link #profile-heading:focus {
  color: #fff !important;
  fill: #fff !important;
}

@media (min-width: 992px) {
  .bg-img1.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar,
  .bg-img2.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar,
  .bg-img3.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar,
  .bg-img4.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
    background-color: var(--dark-body);
  }
  .bg-img1.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header,
  .bg-img1.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidemenu,
  .bg-img2.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header,
  .bg-img2.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidemenu,
  .bg-img3.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header,
  .bg-img3.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidemenu,
  .bg-img4.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header,
  .bg-img4.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidemenu {
    background-color: var(--dark-body);
  }
  @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    .bg-img1.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar,
    .bg-img2.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar,
    .bg-img3.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar,
    .bg-img4.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
      backdrop-filter: inherit !important;
      -webkit-backdrop-filter: inherit !important;
      background-color: transparent;
    }
    .bg-img1.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header,
    .bg-img1.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidemenu,
    .bg-img2.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header,
    .bg-img2.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidemenu,
    .bg-img3.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header,
    .bg-img3.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidemenu,
    .bg-img4.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header,
    .bg-img4.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidemenu {
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px) !important;
      background-color: transparent;
    }
  }
  .bg-img1.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidemenu,
  .bg-img2.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidemenu,
  .bg-img3.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidemenu,
  .bg-img4.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidemenu {
    min-height: 100vh;
  }
  .sidebar-mini.icontext-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header {
    align-items: center;
  }
  .sidebar-mini.light-menu.icon-overlay.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo,
  .sidebar-mini.light-menu.icon-overlay.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo,
  .sidebar-mini.light-menu.icon-overlay.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo,
  .sidebar-mini.light-menu.icontext-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo,
  .sidebar-mini.light-menu.icontext-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo,
  .sidebar-mini.light-menu.icontext-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
    display: none !important;
  }
  .sidebar-mini.light-menu.icon-overlay.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1,
  .sidebar-mini.light-menu.icontext-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: block !important;
  }
  .sidebar-mini.light-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo,
  .sidebar-mini.light-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo,
  .sidebar-mini.light-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1,
  .sidebar-mini.light-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo,
  .sidebar-mini.light-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo,
  .sidebar-mini.light-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
  }
  .sidebar-mini.light-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo,
  .sidebar-mini.light-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
    display: block !important;
  }
  .sidebar-mini.color-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo,
  .sidebar-mini.color-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo,
  .sidebar-mini.color-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1,
  .sidebar-mini.color-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo,
  .sidebar-mini.color-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo,
  .sidebar-mini.color-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1,
  .sidebar-mini.dark-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo,
  .sidebar-mini.dark-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo,
  .sidebar-mini.dark-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1,
  .sidebar-mini.dark-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo,
  .sidebar-mini.dark-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo,
  .sidebar-mini.dark-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
  }
  .sidebar-mini.color-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo,
  .sidebar-mini.color-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo,
  .sidebar-mini.dark-menu.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo,
  .sidebar-mini.dark-menu.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block !important;
  }
}
.horizontal.color-menu.color-header.app .header .nav-link.show i, .horizontal.color-menu.color-header.app .header .nav-link.show #profile-heading, .horizontal.color-menu.color-header.app .header .nav-link.show svg {
  color: #fff !important;
  fill: #fff !important;
}

.color-header.app .header .nav-link #profile-heading, .gradient-header.app .header .nav-link #profile-heading {
  color: #fff !important;
}
.color-header.app .header .nav-link.show i, .gradient-header.app .header .nav-link.show i {
  color: #fff !important;
}
.color-header.app .header .nav-link.show svg, .gradient-header.app .header .nav-link.show svg {
  color: #fff !important;
  fill: #fff !important;
}

.article-detail {
  height: 100%;
  width: 100%;
  background: rgba(143, 189, 86, 0.7);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
}/*# sourceMappingURL=skin-modes.css.map */